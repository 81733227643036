import { useState, useEffect } from "react";
import { ProblemOrderInterface } from "../../types/Order";
import { Icon } from "@iconify/react";
import axios from "axios";

function ProblemOrders() {
    const [isOpen, setIsOpen] = useState(false);
    return <div className="mb-4">
        <div className="flex justify-end items-center">
            <button onClick={() => setIsOpen(true)} className="btn btn-error btn-sm">Проблемные заявки</button>
        </div>
        <OrderDrawer isOpen={isOpen} handleClose={() => setIsOpen(false)} />

    </div>

}

interface IProps {
    isOpen: boolean;
    handleClose: () => void;
}

const OrderDrawer: React.FC<IProps> = ({ isOpen, handleClose }) => {
    const [orders, setOrders] = useState<ProblemOrderInterface[]>([]);


    useEffect(() => {
        if (!isOpen) {
            return;
        }
        axios.get('/problem-orders').then(({ data }) => {
            setOrders(data);
        })
        // if (!isOpen) {
        //     setRequisites([]);
        //     setRequisiteSelectedId('');
        //     setSum('');
        //     setOrderId('');
        //     setFields([]);
        //     return;
        // };
        // axios.get("/requisites/verifyed").then(({ data: list }) => {
        //     setRequisites(list);
        //     if (!requisiteSelectedId) {
        //         setRequisiteSelectedId(list[0]?._id)
        //     }
        // });

    }, [isOpen])

    const submitHandler = () => {

        // setIsSubmit(true)
        // axios.post('/orders/create', {
        //     requisiteId: requisiteSelectedId,
        //     sum: +sum,
        //     fields,
        //     orderId
        // })
        //     .then(() => {
        //         toast.success("Заявка создана!")
        //         handleClose()
        //     })
        //     .finally(() => {
        //         setIsSubmit(false);
        //     })
        // 
    }

    const closeHandler = () => {

        handleClose();
    };

    const doneHandler = (id: string) => {
        axios.put(`/problem-orders/done`, { id }).then(({ data: updated }) => {
            setOrders(orders => orders.map(o => o._id === id ? updated : o));
        })
    }

    const deleteHandler = (id: string) => {
        axios.delete(`/problem-orders`, { data: { id } }).then(() => {
            setOrders(orders => orders.filter(o => o._id !== id));
        })
    }


    return <div>

        <div className="drawer drawer-end absolute z-10">
            <input
                id="requisite-drawer"
                type="checkbox"
                checked={isOpen}
                className="drawer-toggle"
            />

            <div className="drawer-side">
                <label
                    htmlFor="mrequisite-drawe"
                    aria-label="close sidebar"
                    className="drawer-overlay"
                    onClick={closeHandler}
                ></label>
                <div className="menu w-full sm:w-500px min-h-full bg-base-200 text-base-content">
                    <div className="p-3">
                        <div className="flex justify-between items-center mb-4">
                            <p className="text-xl font-bold">Проблемные заявки</p>

                            <button onClick={closeHandler} className="btn btn-circle">
                                <Icon fontSize="26" icon="ic:round-close" />
                            </button>
                        </div>

                        {orders.map(o => <OrderItem key={o._id} order={o} deleteHandler={deleteHandler} doneHandler={doneHandler} />)}
                    </div>
                </div>
            </div>
        </div>
    </div>
}


function OrderItem({ order, deleteHandler, doneHandler }: { order: ProblemOrderInterface, deleteHandler: (id: string) => void, doneHandler: (id: string) => void }) {
    return <div className="mb-2 bg-base-100 py-2 px-4 rounded-md">
        {
            order.status === 'done' && <div className=" w-full text-right text-green-500 font-bold text-lg">Решено</div>
        }
        <div className="mb-2">
            <p className="text-sm font-bold">Пользователь:</p>
            <p>{order?.account?.email}</p>
        </div>
        <div className="mb-2">
            <p className="text-sm font-bold">Комментарий:</p>
            <p>{order.comment}</p>
        </div>
        <div className="mb-2">
            <p className="text-sm font-bold"    >Дата:</p>
            <p>{order.date}</p>
        </div>
        <div className="mb-2">
            <p className="text-sm font-bold">Сумма:</p>
            <p>{order.sum}</p>
        </div>
        {
            order.status === 'pending' && <button onClick={() => doneHandler(order._id)} className="btn mb-2 btn-success w-full btn-sm">Решить</button>
        }
        <button onClick={() => deleteHandler(order._id)} className="btn btn-error w-full btn-sm">
            Удалить
        </button>


    </div>
}


export default ProblemOrders